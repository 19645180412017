/*
 * @Author: lazyzzd
 * @Date: 2022-07-07 21:04:58
 * @LastEditors: lazyzzd
 * @LastEditTime: 2022-07-07 21:48:09
 * @Description: 状态管理接口
 */
import { IListOption, IResponse } from "@/interface";
import request from "../utils/request";

export const getAliStatus = (): Promise<string[]> =>
  request.get("/api/status/ali");

export const getStoreStatus = (): Promise<string[]> =>
  request.get("/api/status/store");

export const getCustomStatus = (): Promise<
  (IListOption<number> & { _id: string })[]
> => request.get("/api/status/custom");

export const deleteCustomStatus = (
  _id: string
): Promise<IResponse<undefined>> =>
  request.post("/api/status/deleteCustom", { _id });

export const addCustom = (label: string): Promise<IResponse<undefined>> =>
  request.post("/api/status/addCustom", { label });
